import {useMutation} from '@apollo/client';
import {
  Close,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';

import CheckboxInput from '../../../../components/input/checkbox';
import Input from '../../../../components/input/input';
import {SelectAnamnesePerguntas} from '../../../../components/input/select/anamnese-perguntas';
import {toastNotification} from '../../../../components/toastify';
import {anamnesePerguntaTipo} from '../../../../constants/enum-labels';
import {UPDATE_ANAMNESE_MODELO} from '../../../../graphql/mutation';
import PerguntaModal from './pergunta';

export default function AvaliacaoModeloModal({modelo = {}, open, onClose}) {
  const [formData, setFormData] = useState({});
  const [perguntaSelecionada, setPerguntaSelecionada] = useState(undefined);
  const [openPerguntaModal, setOpenPerguntaModal] = useState(false);
  const [inputText, setInputText] = useState('');

  const [updateModelo, {loading}] = useMutation(UPDATE_ANAMNESE_MODELO);

  const handleUpdateModelo = () => {
    updateModelo({
      variables: {
        anamneseModelo: {
          id: formData.id,
          nome: formData.nome,
          ativo: formData.ativo,
          perguntas: formData.perguntas.map((p, index) => ({
            id: p.id,
            ordem: index + 1,
            modelo: {id: formData.id},
            pergunta: {
              id: p.pergunta?.id,
              pergunta: p.pergunta?.pergunta,
              tipo: p.pergunta?.tipo,
              modeloGrupo: {id: p.pergunta?.modeloGrupo?.id},
              valoresReferencia: p.pergunta?.valoresReferencia,
              urlReferencia: p.pergunta?.urlReferencia,
            },
          })),
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'Modelo de avaliação salvo!',
          type: 'success',
        });
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    if (!open) return setFormData({});

    setFormData(modelo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const adicionarCampo = (pergunta, index) => {
    if (index === undefined)
      return setFormData((f) => ({
        ...f,
        perguntas: [...(f.perguntas || []), {pergunta}],
      }));

    formData.perguntas[index] = {...formData.perguntas[index], pergunta};
    setFormData({...formData});
  };

  const perguntaUp = (index) => {
    if (index === 0) return;

    const pergunta = formData.perguntas[index];
    formData.perguntas[index] = formData.perguntas[index - 1];
    formData.perguntas[index - 1] = pergunta;

    setFormData({...formData});
  };

  const perguntaDown = (index) => {
    if (index === formData.perguntas.length - 1) return;

    const pergunta = formData.perguntas[index];
    formData.perguntas[index] = formData.perguntas[index + 1];
    formData.perguntas[index + 1] = pergunta;

    setFormData({...formData});
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose} sx={styles.modal}>
        <Slide in={open} appear>
          <Grid
            container
            className="styled-scroll-transparent-desktop"
            sx={styles.container}>
            <Grid container item sx={styles.header}>
              <Typography sx={styles.title}>MODELO DE AVALIAÇÃO</Typography>
              <IconButton sx={styles.closeIcon} onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
            <Grid container item sx={styles.content}>
              <Grid item xs={12}>
                <Input
                  placeholder={'Nome do modelo de avaliação'}
                  name="nome"
                  type="text"
                  value={formData.nome || ''}
                  onChange={({target}) =>
                    setFormData((f) => ({...f, nome: target?.value}))
                  }
                />
              </Grid>
              <Grid container sx={styles.grupoContainer} rowGap={2}>
                <Grid item xs={12}>
                  <SelectAnamnesePerguntas
                    placeholder={'Buscar pergunta'}
                    name="pergunta"
                    onChange={(pergunta) => adicionarCampo(pergunta)}
                    onCreateOption={() => setOpenPerguntaModal(true)}
                    onInputChange={(value) => value && setInputText(value)}
                    creatable
                  />
                </Grid>
                <Grid container>
                  <TableContainer id="table-container">
                    <Table id="table">
                      <TableHead id="table-head">
                        <TableRow>
                          <TableCell>Ordem</TableCell>
                          <TableCell>Pergunta</TableCell>
                          <TableCell>Tipo</TableCell>
                          <TableCell>Grupo</TableCell>
                          <TableCell sx={{width: 100}} />
                        </TableRow>
                      </TableHead>
                      <TableBody id="table-body">
                        {formData.perguntas?.map((modeloPergunta, index) => {
                          const pergunta = modeloPergunta.pergunta;
                          return (
                            <TableRow
                              key={pergunta?.id || index}
                              id="table-row">
                              <TableCell>
                                <Grid container alignItems={'center'}>
                                  <Grid container item width={'60px'}>
                                    <KeyboardArrowUp
                                      disabled={index === 0}
                                      sx={styles.orderIcon}
                                      onClick={() => perguntaUp(index)}
                                    />
                                    <KeyboardArrowDown
                                      disabled={
                                        index === formData.perguntas.length - 1
                                      }
                                      sx={styles.orderIcon}
                                      onClick={() => perguntaDown(index)}
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    {index + 1}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell>{pergunta.pergunta}</TableCell>
                              <TableCell>
                                {anamnesePerguntaTipo[pergunta.tipo]}
                              </TableCell>
                              <TableCell>
                                {pergunta.modeloGrupo?.nome}
                              </TableCell>
                              <TableCell>
                                <Grid container gap={1} sx={{width: '90px'}}>
                                  <IconButton
                                    id="icon-outlined"
                                    onClick={() => {
                                      formData.perguntas.splice(index, 1);
                                      setFormData({...formData});
                                    }}>
                                    <Delete />
                                  </IconButton>
                                  <IconButton
                                    id="icon-primary"
                                    onClick={() => {
                                      setPerguntaSelecionada(index);
                                      setOpenPerguntaModal(true);
                                    }}>
                                    <Edit />
                                  </IconButton>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CheckboxInput
                  name="ativo"
                  label={'Ativo'}
                  value={formData.ativo || false}
                  checked={formData.ativo || false}
                  onChange={({target}) =>
                    setFormData((f) => ({
                      ...f,
                      ativo: target?.checked,
                    }))
                  }
                />
              </Grid>
              <Grid container item xs={12} justifyContent={'flex-end'}>
                <Button
                  id="primary"
                  onClick={handleUpdateModelo}
                  endIcon={loading && <CircularProgress size={20} />}
                  disabled={loading}>
                  Salvar
                </Button>
              </Grid>
            </Grid>
            <PerguntaModal
              pergunta={
                perguntaSelecionada !== undefined &&
                formData.perguntas[perguntaSelecionada]?.pergunta
              }
              novaPergunta={inputText}
              open={openPerguntaModal}
              onClose={() => {
                setOpenPerguntaModal(false);
                setPerguntaSelecionada(undefined);
              }}
              onSave={(pergunta) => {
                adicionarCampo(pergunta, perguntaSelecionada);
                setOpenPerguntaModal(false);
                setPerguntaSelecionada(undefined);
              }}
            />
          </Grid>
        </Slide>
      </Modal>
    </React.Fragment>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxHeight: '90vh',
    width: '80%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    padding: 2,
    justifyContent: 'space-between',
    svg: {
      color: '#fff',
    },
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    padding: '16px',
  },
  grupoContainer: {
    border: '1px solid #fff',
    marginY: 2,
    padding: 2,
    borderRadius: '6px',
  },
  buttonNovo: {
    borderStyle: 'dashed',
    color: '#fff',
    borderColor: '#fff',
  },
  perguntaContainer: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #fff',
    paddingY: 1,
  },
  perguntaTitulo: {
    color: '#fff',
    alignSelf: 'center',
  },
  orderIcon: {
    cursor: 'pointer',
    '&[disabled]': {
      color: '#fff8 !important',
    },
    ':hover': {
      color: 'var(--primary)',
    },
  },
};
